import { Link } from "react-router-dom";
import logo from "../assets/chioma-akpuru-logo.png";

const Footer = () => {
  return (
    <footer className="footer-section-2">
      <div className="container-fluid custom-container">
        {/* Footer Main Start */}
        <div
          className="footer-main align-items-center"
          style={{ paddingTop: "0" }}
        >
          <div className="footer-left-2">
            <div className="footer-about text-lg-start text-center">
              <Link
                className="logo justify-content-lg-start justify-content-center"
                to="/"
              >
                <img src={logo} alt="Logo" className="logoimg" />
              </Link>
            </div>
          </div>
          <div className="footer-right-2">
            <div className="footer-link-2">
              <div className="footer-link__wrapper">
                <h4 className="footer-title-2">Contact</h4>
                <ul className="footer-link-2__list">
                  <li>
                    <a href="mailto:chioma.akpuru@gmail.com">
                      chioma.akpuru@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+2349072645016">09072645016</a>
                  </li>
                </ul>
                <div
                  className="offcanvas-sidebar__social"
                  style={{ marginTop: "10px" }}
                >
                  <ul className="offcanvas-social">
                    <li>
                      <a
                        href="https://www.facebook.com/Cheeoma.akpuru/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="lastudioicon-b-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/cakpuru?s=21&t=IY6-XPKfGMUqkI6osDaoUA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="lastudioicon-b-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/chioma.akpuru?igshid=YmMyMTA2M2Y="
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="lastudioicon-b-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/channel/UCjRBbOPWAAHnndmmlSkPr9A"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="lastudioicon-b-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-link__wrapper">
                <h4 className="footer-title-2">links</h4>
                <ul className="footer-link-2__list">
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ's</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-link__wrapper">
                <h4 className="footer-title-2">Shop</h4>
                <ul className="footer-link-2__list">
                  <li>
                    <Link to="/shop-women">Women</Link>
                  </li>
                  <li>
                    <Link to="/shop-bridal">Bridal</Link>
                  </li>
                  <li>
                    <Link to="/shop-men">Men</Link>
                  </li>
                  <li>
                    <Link to="/shop-runway">Runway</Link>
                  </li>
                  <li>
                    <Link to="/shop-trending">Trending</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Main End */}
        {/* Footer CopyRight Start */}
        <div className="footer-copyright-2">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text-center text-md-start">
                <p>
                  © {new Date().getFullYear()} Chioma Akpuru. Powered by{" "}
                  <a
                    href="https://www.arvotek.co"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#71cee8" }}
                  >
                    Arvotek
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Footer CopyRight End */}
      </div>
    </footer>
  );
};

export default Footer;
